import {
    Action,
    AlertSeverity,
    ButtonValidate,
    Color,
    FieldBlock,
    FlexContent,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutButtons,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    IconTooltip,
    InputText,
    LayoutColumns,
    MainContentPageHeader,
    MainContentStyle,
    Select,
    Tag,
    TagStyle,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {FormEvent, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Container} from "../../api/model/container/Container";
import {ContainerDeploymentDestination} from "../../api/model/container/ContainerDeploymentDestination";
import {ContainerErrorCode} from "../../api/model/container/ContainerErrorCode";
import {ContainerField} from "../../api/model/container/ContainerField";
import {ContainerHostDetails} from "../../api/model/container/ContainerHostDetails";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {FormContainerHostname, IconTitle} from "../../component/snippet";
import {FormLayoutMessage} from "../../common/component/snippet";
import {HelpCarousel} from "../../component/widget";
import {MainContent, Wrapper} from "../../common/component/widget";
import {ModalWelcome} from "../../component/modal";
import {pathHome} from "../../common/utils/constants";
import {session} from "../../api/ApiSession";
import {searchWelcome, TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {Authorization} from "../../api/model/account/Authorization";

type FormErrors = {
    container_config_id: boolean;
};

function ContainerCreate() {
    const navigate = useNavigate();
    const location = useLocation();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    const [container, setContainer] = useState<Container>(new Container());
    const [hostDetails, setHostDetails] = useState<ContainerHostDetails>(new ContainerHostDetails());
    const [formErrors, setFormErrors] = useState<FormErrors>({container_config_id: false});

    const [isRegistered, setRegistered] = useState(location.search === searchWelcome);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [hasAWSRestriction, setAWSRestriction] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const account = await session.getAccount();
                if (account.hasAuthorization(Authorization.AWS)) {
                    handleChange(ContainerField.DEPLOYMENT_DESTINATION, ContainerDeploymentDestination.AWS);
                    setAWSRestriction(true);
                }
            } catch (e) {
            }
        })();
    }, []);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!container.hasValidContainerConfigId()) {
            setFormErrors((prevState) => ({...prevState, container_config_id: true}));
            return;
        }

        try {
            setSubmitting(true);
            const createdContainer = await session.restContainer.create(new Container({...container, [ContainerField.INSTANCE_HOSTS]: [hostDetails.getHostname()]}));
            navigate(createdContainer.getDetailsRoute(), {replace: true});
        } catch (e) {
            UIEventManager.alert({
                text: textContainer(`error.${e instanceof ErrorResponse ? e.code : ContainerErrorCode.FAIL_CREATE_CONF}`, {hostname: hostDetails.getHostname(), domain: hostDetails.domain}),
                severity: AlertSeverity.DANGER,
                isClosable: true
            });
            setSubmitting(false);
        }
    };

    const hasEmptyField = () => !(container.name && container.container_config_id && hostDetails.hasHostnameFilled());

    const handleChange = (field: ContainerField, value: any) => {
        setContainer((prevState) => new Container({...prevState, [field]: value}));
    };

    return (
        <Wrapper>
            <MainContent style={MainContentStyle.FULL_WIDTH} cssClass="container-create">
                <MainContentPageHeader
                    title={textContainer("create_container")}
                    icon={{name: "dns", colorIcon: Color.CYAN, outlined: true}}
                    cssClass="container-create__header"
                />
                <div className="container-create__close">
                    <IconTooltip
                        text={textCommon(Action.CLOSE.labelKey)}
                        icon={{...Action.CLOSE.icon, colorIcon: Color.GREY_DARK}}
                        onClick={() => navigate(location.state?.prevPath || pathHome)}
                    />
                </div>
                <LayoutColumns>
                    <Form className="container-create__form" onSubmit={handleSubmit}>
                        <FormLayoutRows spacing={FlexContentSpacing.LARGE}>
                            <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                <FormLayoutTitle>
                                    <Tag label={textContainer("tag.gtm_container")} style={TagStyle.DEFAULT_OCEAN}/>
                                </FormLayoutTitle>
                                <FieldBlock
                                    name={textContainer(`field.${ContainerField.NAME}`)}
                                    label={textContainer(`field.${ContainerField.NAME}`)}
                                >
                                    <InputText
                                        placeholder={textContainer(`placeholder.${ContainerField.NAME}`)}
                                        value={container.name}
                                        onChange={(value) => handleChange(ContainerField.NAME, value)}
                                        minLength={2}
                                        autoFocus
                                        required
                                    />
                                </FieldBlock>
                                <FieldBlock
                                    name={textContainer(`field.${ContainerField.CONTAINER_CONFIG_ID}`)}
                                    label={textContainer(`field.${ContainerField.CONTAINER_CONFIG_ID}`)}
                                    tooltip={textContainer("tooltip.gtm_container")}
                                    content={{direction: FlexContentDirection.COLUMN}}
                                >
                                    <InputText
                                        placeholder={textContainer(`placeholder.${ContainerField.CONTAINER_CONFIG_ID}`)}
                                        value={container.container_config_id}
                                        onChange={(value) => {
                                            setFormErrors((prevState) => ({...prevState, [ContainerField.CONTAINER_CONFIG_ID]: false}));
                                            handleChange(ContainerField.CONTAINER_CONFIG_ID, value);
                                        }}
                                        required
                                    />
                                    {!!formErrors?.container_config_id &&
                                        <FormLayoutMessage message={textContainer(`error.${ContainerErrorCode.INVALID_CONTAINER_CONFIG_ID}`)} severity={AlertSeverity.DANGER}/>
                                    }
                                </FieldBlock>
                                <FieldBlock
                                    name={textContainer(`field.${ContainerField.DEPLOYMENT_DESTINATION}`)}
                                    label={textContainer(`field.${ContainerField.DEPLOYMENT_DESTINATION}`)}
                                    tooltip={textContainer(`tooltip.${ContainerField.DEPLOYMENT_DESTINATION}`)}
                                >
                                    <Select
                                        value={container.deployment_destination}
                                        options={Object.values(ContainerDeploymentDestination)
                                            .filter((item) => !hasAWSRestriction || item === ContainerDeploymentDestination.AWS)
                                            .map((item) => ({value: item, label: item}))
                                        }
                                        onChange={(option) => handleChange(ContainerField.DEPLOYMENT_DESTINATION, option?.value)}
                                        required
                                    />
                                </FieldBlock>
                            </FormLayoutRows>
                            <FormLayoutSeparator/>
                            <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                <FormLayoutTitle>
                                    <Tag label={textContainer("tag.hostname")} style={TagStyle.DEFAULT_OCEAN}/>
                                </FormLayoutTitle>
                                <FormContainerHostname hostDetails={hostDetails} onChange={setHostDetails}/>
                            </FormLayoutRows>
                            <FormLayoutButtons>
                                <ButtonValidate disabled={hasEmptyField() || !hostDetails.hasValidHostname()} loading={isSubmitting}/>
                            </FormLayoutButtons>
                        </FormLayoutRows>
                    </Form>
                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM} cssClass="container-create__tutorial">
                        <IconTitle icon={{name: "lightbulb", colorIcon: Color.OCEAN, outlined: true}} title={textContainer("configure_hosting_tutorial.title")}/>
                        <HelpCarousel
                            ariaLabel="configure-hosting-tutorial"
                            slides={textContainer("configure_hosting_tutorial.steps", {returnObjects: true})}
                        />
                    </FlexContent>
                </LayoutColumns>
                <ModalWelcome active={isRegistered} onClose={() => setRegistered(false)}/>
            </MainContent>
        </Wrapper>
    );
}

export default ContainerCreate;
